<template>
  <div class="px-4 font-Noto-Sans user-list-page">
    <v-sheet color="transparent">
      <v-row>
        <v-col cols="12" class="pl-0 pr-0">
          <v-container class="mobile-view">
            <v-img
              :src="require('@/assets/images/logo-small.png')"
              max-height="37"
              height="37"
              max-width="37"
              width="37"
              class="rounded-circle m-auto"
              lazy
            ></v-img>
            <p class="page-head">プライバシーポリシー</p>
            <div class="page-content">
              <p class="mb-3">
                株式会社ＦＮＣ（以下，「当社」といいます。）は，インターネット上の求職・求人情報提供サービスサイトやその他サービス、または関連するアプリケーションもしくはメールサービス等の求職・求人情報提供サービス（以下、総称して「各コンテンツ」といいます）の利用者から取得した個人情報等の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
              </p>
              <p class="mb-0">第1条（個人情報）</p>
              <p class="mb-3">
                「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
              </p>

              <p class="mb-0">第２条（取得する個人情報）</p>
              <p class="mb-0">
                １．当社は、以下の情報を取得することがあります。
              </p>
              <p class="mb-0">
                （１）氏名、性別、年齢、住所、生年月日、電話番号、ＦＡＸ番号、メールアドレス、職業、学歴、写真、動画その他各コンテンツのご利用にあたって必要となる情報
              </p>
              <p class="mb-0">
                （２）利用者の端末に関する情報（端末情報）、位置情報、Advertising
                Identifier (iOS) またはAdvertising ID
                (Android)（広告識別子）、アクセスログ、Cookie情報
              </p>
              <p class="mb-0">
                ２．当社は、利用者のプライバシーの保護、利便性の向上、広告の配信および統計データの取得のため、Cookieを使用します。また、CookieやJavaScript等の技術を利用して、ご提供いただいた情報のうち、年齢、性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）、端末情報、各コンテンツ内における利用者の行動履歴（アクセスしたURL、コンテンツ、参照順等）、位置情報、広告識別子を取得することがあります。また、当社では、Cookie情報等の行動履歴等の保存・参照等を第三者に委託して、利用者に適切な広告を配信するための行動ターゲティング広告サービスや利用状況把握のためのアクセス解析サービスを利用しています。
              </p>
              <p class="mb-3">
                ３．各コンテンツでは、その利用状況を把握するために、Googleアナリティクスを利用することがあります。Googleアナリティクスによるアクセス情報の収集方法および利用方法については、Googleアナリティクス利用規約およびGoogle社のプライバシーポリシーについては、以下のページをご参照ください。<a
                  href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                  target="_blank"
                  >Google Analyticsの利用規約</a
                >
                <a
                  href="https://policies.google.com/privacy?hl=ja"
                  target="_blank"
                  >Google社のプライバシーポリシー</a
                >
              </p>

              <p class="mb-0">第３条（個人情報を収集・利用する目的）</p>
              <p class="mb-0">
                当社が個人情報を収集・利用する目的は，以下のとおりです。
              </p>
              <p class="mb-0">
                （１）本サービスの提供及び本サービスに関するご連絡
              </p>
              <p class="mb-0">
                （２）利用者（本サービスを利用する方をいいます。）の個人認証または本人確認
              </p>
              <p class="mb-0">
                （３）利用者の承諾に基づく各コンテンツ利用企業・提携企業・団体等（以下「利用企業等」といいます）への個人情報の提供
              </p>
              <p class="mb-0">
                （４）各コンテンツの利用に伴う連絡・メールマガジン・アンケート・各種お知らせ等の配信
              </p>
              <p class="mb-0">
                （５）属性情報･端末情報・位置情報・行動履歴等に基づく広告・コンテンツ等の配信・表示・サービスの提供
              </p>
              <p class="mb-0">
                （６）キャンペーン・アンケート・モニター・取材等の実施
              </p>
              <p class="mb-0">（７）就職・転職支援サービスのご案内、ご提供</p>
              <p class="mb-0">（８）求職の条件にあった会社への提供</p>
              <p class="mb-0">（９）イベント等の開催のご案内</p>
              <p class="mb-0">
                （１０）サービスの改善・新規サービスの開発・利用状況の調査・分析またはマーケティング
              </p>
              <p class="mb-0">（１１）お問い合わせ等に対する回答</p>
              <p class="mb-0">
                （１２）利用禁止行為（営利目的・虚偽・いたずら等の応募・情報提供・登録）の調査・確認
              </p>
              <p class="mb-0">
                （１３）有料サービスにおいて，利用者に利用料金を請求するため
              </p>
              <p class="mb-0">
                （１４）本サービスの利用に関する統計データを作成するため
              </p>
              <p class="mb-0">
                （１５）今後の本サービスの利用に関する企画、立案又は実施のため
              </p>
              <p class="mb-3">（１６）上記の各利用目的に付随する目的</p>

              <p class="mb-0">第４条（第三者提供等）</p>
              <p class="mb-0">
                １．当社は、利用者の求人応募手続遂行のため、必要な範囲内で利用者の顔、氏名、性別、生年月日、住所、電話番号、メールアドレス、在留資格等の個人情報を各サイトの利用企業等に第三者提供する場合があります。
              </p>
              <p class="mb-0">
                ２．業務提携先事業者等と利用者への総合サービスの提供のため、個人情報を共同利用する場合があります。その場合、あらかじめ利用目的、データ項目、利用者の範囲および管理責任者を明確にし、通知または公表します。また、共同利用者間での安全管理を徹底します。
              </p>
              <p class="mb-0">
                ３．個人情報の取扱いの全部または一部を委託する場合があります。この際は、個人情報の取扱いに関する契約を締結し、委託先に対する必要かつ適切な監督を行います。
              </p>
              <p class="mb-0">
                ４．当社は，次に掲げる場合，あらかじめ利用者の同意を得ることなく，第三者に個人情報を提供することがあります。また、個人情報保護法その他の法令で認められる場合も同様とします。
              </p>
              <p class="mb-0">
                （１）人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
              </p>
              <p class="mb-0">
                （２）公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
              </p>
              <p class="mb-0">
                （３）国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
              </p>
              <p class="mb-0">
                ５．前項の定め以外において、次に掲げる場合には、当該情報の提供先（日本国外にある者を含みます。）は第三者に該当しないものとします。
              </p>
              <p class="mb-0">
                （１）当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              </p>
              <p class="mb-0">
                （２）合併その他の事由による事業の承継に伴って個人情報が提供される場合
              </p>
              <p class="mb-0">
                （３）個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
              </p>
              <p class="mb-0">
                （４）本サービスを利用するにあたって業務提携先事業者等が提供するサービスを利用する場合における業務提携先事業者等が取得した情報
              </p>
              <p class="mb-3">
                ６．当社は、当社が取得したCookie等の情報と、当社以外のサイトを訪問した際に記録された当社以外のCookie等の情報によって取得された情報を個人が識別できない形で組み合わせて、当社および当社以外のマーケティング活動（広告配信含む）への利用を行う場合があります。
              </p>

              <p class="mb-0">第５条（利用目的の変更）</p>
              <p class="mb-0">
                １．当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
              </p>
              <p class="mb-3">
                ２．利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，利用者に通知し，または本ウェブサイト上に公表するものとします。
              </p>

              <p class="mb-0">第６条（統計データの利用）</p>
              <p class="mb-0">
                １．当社は、個人を特定できないように加工した利用状況や統計データを作成し、当該情報について何ら制約なく利用することができることとします。なお、この場合の著作権は当社に帰属します。
              </p>
              <p class="mb-3">
                ２．当社は、個々の利用者を識別できる情報を含まない統計的に処理した情報を公表または販売することがあります。
              </p>

              <p class="mb-0">第７条（個人情報の開示）</p>
              <p class="mb-0">
                １．当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1,000円の手数料を申し受けます。
              </p>
              <p class="mb-0">
                （１）本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
              </p>
              <p class="mb-0">
                （２）当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
              </p>
              <p class="mb-0">（３）その他法令に違反することとなる場合</p>
              <p class="mb-0">
                ２．前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
              </p>
              <p class="mb-3">
                ３．開示の請求に当たっては、個人を識別できる情報（氏名､性別、年齢、住所､生年月日、電話番号､ＦＡＸ番号、メールアドレス､職業、その他同一性の確認に必要な項目）により、ご本人であることを確認します。ただし、本人以外が個人を識別できる情報を入手し使用した場合、当社は責任を負いません。
              </p>

              <p class="mb-0">第８条（個人情報の訂正及び利用停止等）</p>
              <p class="mb-0">
                １．当社は、利用者から、①個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び②あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、利用者ご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨を利用者に通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、利用者に対しその旨を通知いたします。
              </p>
              <p class="mb-0">
                ２．当社は、利用者から、利用者の個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、利用者ご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨を利用者に通知します。
              </p>
              <p class="mb-0">
                ３．個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前２項の規定は適用されません。
              </p>
              <p class="mb-3">４．本条において、前条第３項を準用します。</p>

              <p class="mb-0">第９条（プライバシーポリシーの変更）</p>
              <p class="mb-0">
                １．本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
              </p>
              <p class="mb-3">
                ２．当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
              </p>

              <p class="mb-0">第１０条（お問い合わせ窓口）</p>
              <p class="mb-3">
                本ポリシーに関するお問い合わせは，当社事務局（aaa@co.jp）までお願いいたします。なお，当社の所在地，代表者氏名は当社ホームページ（<a
                  href="https://www.fnc-job.com/profile"
                  target="_blank"
                  >https://www.fnc-job.com/profile</a
                >）にてご確認ください。
              </p>

              <p class="mb-0">制定日　２０２１年１０月１日</p>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TermsAndConditions',
  components: {},
  metaInfo: {
    title: 'コトナル'
  },
  data() {
    return {
      initialLoad: true,
      loading: false
    }
  },
  created() {},
  computed: {},
  methods: {}
}
</script>

<style lang="scss" src="./__style.scss" scope></style>
